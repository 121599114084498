/* footer css for ipad portrait */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .footer-legal .menu li {
    padding-right: 25px !important;
  }
}
/* footer css for ipad landscape */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  .page-navigation .level-3 {
    margin: 5px 0 !important;
  }
}
/* font style */
@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.product_brief__button--add-to-bag,
.product_brief__sub-panel-buttons-container {
  width: 190px !important;
}
/* footer css for ipad portrait */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .footer-legal .menu li.last {
    margin-top: -57px !important;
    margin-right: 116px !important;
  }
}

.header__headline,
.mpp__header,
.headline--tertiary {
  font-family: 'Optima Display Roman' !important;
}

.brand-renutriv {
  .mpp__header,
  .header__headline,
  .headline--tertiary {
    font-family: $bb-roman !important;
  }
}
